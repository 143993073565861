<template>
  <b-container class="col">
    <b-form-group label="Select Date" class="mb-2">
      <flat-pickr
        v-model="date"
        :config="{
          dateFormat: 'Y-m-d',
          defaultDate: 'today',
        }"
        class="form-control font-small-2 w-auto"
        placeholder="Choose Date"
        readonly="readonly"
        @input="getOrders(date)"
      />
    </b-form-group>
    <b-card>
      <MyTable
        :columns="columns"
        :rows="rows"
        :is-loading="isLoading"
        :searchEnabled="true"
      >
        <template v-slot="{ props }">
          <span v-if="props.column.field == 'id'">
            <b-button variant="gradient-primary" block>{{
              props.row.id
            }}</b-button>
          </span>
          <span v-else-if="props.column.field === 'sender_location.first_name'">
            {{ props.row.sender_location.first_name }}
            {{ props.row.sender_location.last_name }}
          </span>
          <span
            v-else-if="props.column.field === 'recipient_location.first_name'"
          >
            {{ props.row.recipient_location.first_name }}
            {{ props.row.recipient_location.last_name }}
          </span>
          <span v-else-if="props.column.field === 'logpoint'">
            <span v-if="props.row.vehicle == 'car'">
              {{ props.row.logpoint_car }}
            </span>
            <span v-else-if="props.row.vehicle == 'motor'">
              {{ props.row.logpoint_motor }}
            </span>
            <span v-else>
              {{ props.row.logpoint_truck }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </MyTable>
    </b-card>
  </b-container>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";

export default {
  components: {
    flatPickr,
    MyTable,
  },

  data() {
    return {
      rows: [],
      date: new Date().toISOString(),
      pageLength: 5,
      isLoading: false,
      columns: [
        {
          label: "Order ID",
          field: "id",
        },
        {
          label: "Pickup Date/Time",
          field: "pickup_date",
        },
        {
          label: "Sender's Name",
          field: "sender_location.first_name",
        },
        {
          label: "Recipient's Name",
          field: "recipient_location.first_name",
        },
        {
          label: "Origin Address",
          field: "sender_location.address_line_1",
        },
        {
          label: "Destination Address",
          field: "recipient_location.address_line_1",
        },
        {
          label: "Door To Door",
          field: "is_door2door",
        },
        {
          label: "Return trip",
          field: "is_returnTrip",
        },
        {
          label: "Vehicle",
          field: "vehicle",
        },
      ],
    };
  },

  created() {
    this.getOrders();
  },
  methods: {
    async getOrders(date = new Date().toISOString()) {
      this.isLoading = true;
      const formattedDate = date.split("T")[0];
      const response = await this.$http.get(
        `orderminidc/filterOrders?order_type=Ondemand&pickup_date=${formattedDate}`
      );
      this.rows = response.data.data;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "src/assets/scss/style.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
